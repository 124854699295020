/*
 * @Description: 
 * @Author: xiaojianping
 * @Date: 2023-03-22 15:18:52
 * @LastEditTime: 2023-05-26 10:04:41
 * @LastEditors: xiaojianping
 */
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import element from 'element-ui'
import * as ECharts from 'echarts'
import 'element-ui/lib/theme-chalk/index.css'
// import "swiper/swiper.min.css"


import Base64 from './api/base64.js' 

Vue.prototype.$Base64 = Base64;



import scroll from 'vue-seamless-scroll'
Vue.use(scroll)


Vue.config.productionTip = false
Vue.prototype.$echarts = ECharts
Vue.use(element)
new Vue({
	render: h => h(App),
	store,
	router,
}).$mount('#app')
window.document.title = "山东国赢大数据产业有限公司"
import VueAMap from 'vue-amap'
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
	key: '5477a814aa74f27ec837da20352aad9b', //上面步骤提到的key复制过来
	plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
	v: '1.4.4'
});

router.beforeEach((to, from, next) => {
	//路由发生改变时,触发
	window.document.title = to.meta.title == undefined ? '默认标题' : to.meta.title
	// if (to.meta.requireAuth) {
	// 	let token = Cookies.get('access_token');
	// 	let anonymous = Cookies.get('user_name');
	// 	if (token) {
	// 		next({
	// 			path: '/login',
	// 			query: {
	// 				redirect: to.fullPath
	// 			}
	// 		})
	// 	}
	// }
	next();
})