<template>
  <div class="nav">
    <img class="nav-logo" :src=siteLogo @click="goindex"/>
    <!-- <img class="nav-logo" src='@/assets/image/uuu.svg' @click="goindex"/> -->
    <div class="nav_menu">
      <!-- <el-menu :default-active="activeIndex"  class="el-menu-demo menu menuA"  mode="horizontal" @select="handleSelect" 
         active-text-color="#3A65F7" text-color="#333333" router >
        <Menu :menuData="menuData"></Menu>
        <el-menu-item index="/" >首页</el-menu-item>
        <el-submenu index="/product">
          <template slot="title">公司产品</template>
          <el-menu-item index="综合交易系统">
            <img  class="img" src="../../assets/svg/Frame1.svg"/>
            综合交易系统
          </el-menu-item>
        </el-submenu>
      </el-menu> -->
       <!-- <el-tabs v-model="activeName" @tab-click="handleClick" ref="tabs">
           <el-tab-pane :label="item.label" :name=item.id.toString() v-for="(item,index) in menuData" :key="item.id"  @mouseenter="yiruhand(item)">
                <div class="fixeddiv" v-if="isshow" @mouseleave.stop ="yichu">
                  <div class="itemdiv">
                    <div class="itemA">
                      {{ label }}
                    </div>
                    <div class="itemB" v-if="iscpshow">
                       <div class="itemBli" v-for="item in larr" :key="item.id" @click="skip(item)">
                         <div class="itemBliA">
                           <div class="itemBliAimg">
                              <img :src="url + item.columnPictrue" alt="" class="itemBliAimg" v-if="item.columnPictrue">
                           </div>
                           <div class="itemBliAtitle">
                            {{ item.label }}
                           </div>
                         </div>
                         <div class="itemBliB">
                          {{ item.columnCharacteristic }}
                         </div>
                       </div>
                    </div>
                    <div class="itemC" v-if="!iscpshow">
                        <div class="itemCli" v-for="item in larr" :key="item.id" @click="skip(item)">
                           <div class="itemCliA">
                               <img :src="url + item.columnPictrue" alt="" class="itemCliA" v-if="item.columnPictrue">
                           </div>
                           <div class="itemCliB">
                            {{ item.label }}
                           </div>
                        </div>
                    </div>
                  </div>
                </div>
          </el-tab-pane>
      </el-tabs> -->
      <!-- @mouseleave.stop ="yichu" -->
      <div class="Atab">
          <div class="Atabitem"  v-for="(item,index) in menuData" :key="item.id" 
                @click="dianjihand(item)" @mouseover="yiruhand(item)" :class="{'activeCss':activeVar==item.id}">
            {{ item.label }}
          </div>
      </div>
    </div>
    <transition name="slide">
      <div class="fixeddiv" v-if="isshow" @mouseleave.stop ="yichu" >
                  <div class="itemdiv">
                    <div class="itemA">
                      {{ label }}
                    </div>
                    <div class="itemB" v-if="iscpshow">
                       <div class="itemBli" v-for="item in larr" :key="item.id" @click="skip(item)">
                         <div class="itemBliA">
                           <div class="itemBliAimg">
                              <img :src="url + item.columnPictrue" alt="" class="itemBliAimg" v-if="item.columnPictrue">
                           </div>
                           <div class="itemBliAtitle">
                            {{ item.label }}
                           </div>
                         </div>
                         <div class="itemBliB">
                          <!-- {{ item.columnCharacteristic }} -->
                         </div>
                       </div>
                    </div>
                    <div class="itemC" v-if="!iscpshow">
                        <div class="itemCli" v-for="item in larr" :key="item.id" @click="skip(item)">
                           <div class="itemCliA">
                               <img :src="url + item.columnPictrue" alt="" class="itemCliA" v-if="item.columnPictrue">
                           </div>
                           <div class="itemCliB">
                            {{ item.label }}
                           </div>
                        </div>
                    </div>
                  </div>
    </div>
     
    </transition>


  </div>
</template>

<script>
import  Menu  from "@/components/TopNav/menu.vue"
import {get} from '@/api/api'
export default {
  name: "TopNav",
  components: { Menu },
  data() {
    return {
      url:'',
      isshow:false,
      iscpshow:false,
      activeName:'',
      activeIndex: "",
      siteLogo:"",
      menuData:[],
      columnCharacteristic:"",//特点
      label:'',//公司名称
      larr:[],
      activeVar:""

    };
  },
  mounted() {
    this.getwebid()
    this.getlanmu()
    this.url = sessionStorage.getItem("A_URL")

  },
  methods: {
    getwebid(){
      get("/web/site/" + sessionStorage.getItem("hrefl")).then(res=>{
        // console.log("站点管理顶部",res.data)
        let list = res.data
        //Logo
        this.siteLogo =  sessionStorage.getItem("A_URL") + list.siteLogo
      })
    },
    //获取栏目名称
    getlanmu(){
      get("/web/site/column/" + sessionStorage.getItem("siid") + "/" + 1).then(res=>{
        // console.log("栏目名称",res.data)
        let list = res.data
        this.activeIndex = list[0].id.toString()
        this.activeName = list[0].id.toString()
        if(this.$route.query.typeid){
          this.activeVar = this.$route.query.typeid
        }else{
          this.activeVar = list[0].id.toString()
        }
        this.cunid = list[0].id.toString()
        // console.log( this.activeVar,' this.activeVar')
        this.menuData = list
        sessionStorage.setItem("lanmulist", JSON.stringify(list));
      })
    },
    //原来组件  弃用
    handleSelect(key, keyPath) {
      this.activeIndex = key
      // console.log(keyPath)
      sessionStorage.setItem("lanmuarr", JSON.stringify(keyPath));
    },
    //更换样式组件  弃用
    handleClick(tab){
      // console.log(tab._props.name)
      //拿到item、
      let item = this.menuData.filter((item) => item.id == tab._props.name)[0];
      //拿到栏目标题
      this.label = item.label
      //跳转的铺垫
      let arr = []
      arr.push(tab._props.name)
      sessionStorage.setItem("lanmuarr", JSON.stringify(arr));
      //获取下标
      let xiaid  =  this.menuData.findIndex(e => e.id == item.id)
      // console.log(item)
      // console.log(xiaid)
      if(item.children){
        this.isshow = true
        if(xiaid == 1){
          this.iscpshow = true
        }else{
          this.iscpshow = false
        }
        this.larr = item.children
      }else{
        this.isshow = false
        this.$router.push({
        path: item.path,
        query: { id: item.id },
        });
      } 
	},
  //点击栏目事件
  dianjihand(item){
    if(item.children){
         if(item.path == '/aboutus'){
              this.activeVar = item.id
              this.$router.push({
              path: item.path,
              query: {typeid:item.id },  //未加密

              // query: {typeid:this.$Base64.encode(item.id) },

              });
         }else{
              // this.activeVar = item.id
              this.$router.push({
              path: item.path,
              query: { id: item.children[0].id,typeid:item.id }, //未加密

              // query: { id: this.$Base64.encode(item.children[0].id) ,typeid: this.$Base64.encode(item.id) }
        });
      }  
      sessionStorage.setItem("lanmuarrisid", item.id);
    let arr = []
    arr.push(item.id)
    sessionStorage.setItem("lanmuarr", JSON.stringify(arr));
    this.isshow =false
    this.iscpshow = false
    }else{
        this.activeVar = item.id
        this.$router.push({
        path: item.path,
        query: { id: item.id, typeid:item.id},  //未加密

        // query: { id: this.$Base64.encode(item.id), typeid: this.$Base64.encode(item.id)}
        });
        let arr = []
        arr.push(item.id)
        sessionStorage.setItem("lanmuarr", JSON.stringify(arr));
        this.isshow =false
        this.iscpshow = false
    }
  },
  yiruhand(item){
    // console.log('移入事件',item)
    //拿到栏目标题
    if(item.path != '/cooperate'){
      this.label = item.label
    //获取下标
    let xiaid  =  this.menuData.findIndex(e => e.id == item.id)
    if(item.children){
        this.isshow = true
        if(xiaid == 1){
          this.iscpshow = true
        }else{
          this.iscpshow = false
        }
        this.larr = item.children
      }else{
        this.isshow = false
      } 
    }else if(item.path == '/cooperate'){
      this.label = item.label

      this.isshow = false
      
    }
    
  },
  //点击跳转页面
  skip(item){
    // console.log(item)
    let arr = []
    arr.push(item.parentId)
    sessionStorage.setItem("lanmuarr", JSON.stringify(arr));
    // this.activeVar = item.parentId
    this.$router.push({
        path: item.path,
        query: { id: item.id, typeid:item.parentId},
        });
    this.isshow =false
    this.iscpshow = false
  },
  //鼠标移出
  yichu(){
     this.isshow =false
     this.iscpshow = false
  },
  //点击图标返回首页
  goindex(){
    this.$router.push({
        path: "/",
        query: {  typeid:this.cunid},
        });
  }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.activeVar = route.query.typeid ? route.query.typeid : ''
        // console.log(this.activeVar,"this.activeVar")

      },
    },
  },
};
</script>
<style scoped lang="less">

.slide-leave,   // 离开前,进入后透明度是1
.slide-enter-to {
  transform: translate(0,0px);
  opacity: 1;
}

.slide-enter-active, .slide-leave-active {
  transition: all .5s ease;
}
.slide-enter, .slide-leave-to {
  transform: translate(0,40px);
  opacity: 0;
}
.Atab{
  width: 100%;
  height: 77px;
  display: flex;
  align-items: center;
  justify-content: end;
}
.Atabitem{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 60px;
  font-size: 16px;
  cursor:pointer;
  border-bottom:3px solid #fff;
}
.activeCss{
  border-bottom:3px solid #3A65F7 ;
  color: #3A65F7;
}
.Atabitem:hover{
  border-bottom:3px solid #3A65F7 ;
  color: #3A65F7;
}


.fixeddiv{
  width: 100%;
  background: #fff;
  position: fixed;
  top: 80px;
  left: 0;
}
.itemdiv{
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 35px;
}
.itemA{
  width: 100%;
  margin-top: 16px;
  font-size: 18px;
  color: #333;
  font-weight: bold;
  overflow:hidden; 
  text-overflow:ellipsis; 
  white-space:nowrap; 
  text-align: left;
}
.itemB{
  width: 100%;
  display: flex;
  flex-wrap: wrap;  
 
}
.itemBli{
  width: 220px;
  height: 60px;
  border-radius: 5px;
  background: #fff;
  padding: 8px 10px;
  box-sizing:border-box; 
  margin-top: 20px;
  margin-right: 17px;
  cursor:pointer;

}
.itemBli:hover{
  background: #F5F6F7;
}
.itemBliA{
  display: flex;
}
.itemBliAimg{
  width: 28px;
  height: 28px;
  object-fit: cover;
}
.itemBliAtitle{
  width: 240px;
  height: 28px;
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-left: 7px;
  color: #333;
  overflow:hidden; 
  text-overflow:ellipsis; 
  white-space:nowrap; 
  text-align: left;
}
.itemBliB{
  width: 240px;
  margin-left: 36px;
  height: 17px;
  color: #6D6D6D ;
  font-size: 13px;
  overflow:hidden; 
  text-overflow:ellipsis; 
  white-space:nowrap; 
  text-align: left;
}

// 第二种样式
.itemC{
  display: flex;
  flex-wrap: wrap;

}
.itemCli{
  width: 170px;
  height: 158px;
  border-radius: 6px;
  overflow: hidden;
  margin-top: 20px;
  margin-right: 70px;
  cursor:pointer;
}
.itemCliA{
  width: 170px;
  height: 104px !important;
  object-fit: cover; 
}

.itemCliB{
  width: 170px;
 height: 20px;
 margin-top: 15px;
 font-size: 15px;
 color: #333;
 text-align: center;
 overflow:hidden; 
 text-overflow:ellipsis; 
 white-space:nowrap; 
}


.nav {
  width: 100%;
  height: 80px;
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  top: 0;
  // border: 1px solid #bec9db;
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
}
.nav-logo {
  width: 311px;
  height: 45px;
  object-fit: cover;
  cursor:pointer;
}
.nav_menu {
  min-width: 889px;
  display: flex;
  // justify-content: end;
  justify-content: flex-end;
}
::v-deep(.el-tabs__header) {
    border-bottom: 1px solid #dcdcdc;
    margin: 0px;
  }
  // ::v-deep(.el-tabs__nav-wrap) {
  //   width: 1200px;
  //   margin: auto;
  //   border: none;
  // }
  ::v-deep(.el-tabs__nav-wrap::after) {
    height: 0px;
  }
  ::v-deep(.el-tabs__item.is-active) {
    color: #3a65f7;
  }
  ::v-deep(.el-tabs__active-bar) {
    height: 3px;
    background-color: #3a65f7;
  }
  ::v-deep(.el-tabs__item) {
    height: 82px !important;
    font-size: 16px !important;
    line-height: 82px !important;
    padding-right:60px ;
  }
  ::v-deep(.el-tabs__item:hover) {
    color: #3a65f7;
  }
  // ::v-deep(.el-tabs--left .el-tabs__item.is-left) {
  //   text-align: left !important;
  // }
</style>