<template>
  <div class="bottom_bg">
    <div class="bottom">
      <div class="bottom_type">
        <!-- <div class="type_left">
          <div class="type_leftone">
            <div class="left_title">公司产品</div>
            <div class="left_detail">
              <span v-for="(item, index) in cparr" :key="index" @click="jiejue(item)" >{{
                item.label
              }}</span>
            </div>
          </div>
          <div class="type_leftone">
            <div class="left_title">解决方案</div>
            <div class="left_detail">
              <span v-for="(item, index) in jjarr" :key="index" @click="jiejue(item)" >{{
               item.label.replace(/解决方案/g,"")
              }}</span>
            </div>
          </div>
        </div>
        <div class="type_right">
          <div class="type_rightitemo">地址：{{address }}</div>
          <div class="type_rightitem">电话：{{phone}}</div>
        </div> -->
        <div class="bom-H bom-Hxian">
          <div class="bom-H-L">
            友情链接
          </div>
          <!-- <div class="bom-H-R bomitemR">
            <span class="yqitem" :class="isbor" v-for="(item , index) in siteNWebs" :key="index"><a :href="item.webAddress" target="_blank" class="urlaa">{{ item.webName }}</a></span>
          </div> -->
          <div class="bom-H-R bomitemR"   ref="myBox">
            <span class="yqitem"  :ref="'myBox' + index" :class="isbor" v-for="(item , index) in siteNWebs" :key="index"><a :href="item.webAddress" target="_blank" class="urlaa">{{ item.webName }}</a></span>
          </div>
        </div>

        <div class="bom-H">
          <div class="bom-H-L">
            联系我们
          </div>
          <div class="bom-H-R">
            <div class="lxitem">地址：{{address }}</div>
            <div class="lxitem">电话：{{phone}}</div>
          </div>
        </div>

      </div>
      <div class="bottom_info">
        <a :href="managementSystem" target="_blank" class="urlaa">{{ copyrightTime }} {{ copyrightAll }} 
        <a href="https://beian.miit.gov.cn/#/Integrated/index" 
        target="_blank" class="urlaa" >经营许可证号: &nbsp;{{ recordNo }}</a> </a>
        &nbsp; &nbsp; 支持ipv6
      </div>
      <div style="width: 100%;display: flex; align-items: center; justify-content: center;font-size: 14px;padding-bottom: 20px;">
        <img src="../../assets/image/beian.png"  style="margin: 0px 4px 0px 0px;"/>
        <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37010102001734" target="_blank" class="urlaa"> 鲁公安网备案:{{ publicSecurity }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import {get} from '@/api/api'
export default {
  name: "BottomNav",
  data() {
    return {
      copyrightTime:"",
      copyrightAll:"",
      recordNo:"",
      address:"",
      phone:"",
      cparr: [
      ], //产品集合
      jjarr: [], //解决方案集合
      managementSystem:'',
      publicSecurity:'',
      siteNWebs:[],
      jisuan : '',
    };
  },
  mounted() {
    this.getwebid()
    // this.getlanmu()

  },
  computed:{
    isbor(){
      this.$nextTick(() => {
        let arr = document.querySelectorAll(".yqitem")
        let dom = document.querySelector(".bomitemR")
        let that = this
        arr.forEach(function(i){
          if(i.offsetLeft - dom.offsetLeft <= 0){
            i.style.borderLeft = 'none';
            i.style. paddingLeft = '0px';
            return that.jisuan
          }
        })
        return that.jisuan
      })
    }
  },
  methods:{
    getwebid(){

      get("/web/site/" + sessionStorage.getItem("hrefl")).then(res=>{
        // console.log("站点管理底部",res.data)
        let list = res.data

       //地址
       this.address = list.address

       //电话
       this.phone = list.phone
       
      //  //产品
      //  if(list.companyProduct){
      //   this.ProductList = list.companyProduct.split(",")
      //  }
      //  //方案
      //  if(list.solveMethod){
      //   this.SolutionList = list.solveMethod.split(",")
      //  }
       //版权时间
       this.copyrightTime = list.copyrightTime

       //版权所有
       this.copyrightAll = list.copyrightAll

       //经营许可证号
       this.recordNo = list.recordNo

       //鲁公安网备案

       this.publicSecurity = list.publicSecurity

       //链接

       this.managementSystem = list.managementSystem


      //拿到公司列表

      this.siteNWebs = list.siteNWebs

      this.jisuan = 'js'




      // this.$nextTick(() => {
      //   let that = this
      //   this.siteNWebs.forEach((item, index) => {
      //     let domRef = "myBox" + index;
      //     if(item){
      //       console.log(that.$refs[domRef][0].getBoundingClientRect().left)
      //       if (that.$refs[domRef][0].getBoundingClientRect().left <= 0){
      //           that.$refs[domRef][0].style.border = "none"
            
      //       }
      //     }
      //   })
      // })

      })
    },

     //获取栏目名称
     getlanmu(){
      get("/web/site/column/" + sessionStorage.getItem("siid") + "/" + 1).then(res=>{
        let list = res.data
        let gscparr =  list.filter((item) => item.path == '/product')
        let jjfaarr = list.filter((item) => item.path == '/Solution')
         //拿到公司产品
        let gscp =gscparr[0].children
        if(gscp.length > 5){
          this.cparr = gscp.slice(0, 5);
        }else{
          this.cparr = gscp
        }
        // console.log("公司产品",this.cparr)
         //拿到解决方案
        let jjfa =jjfaarr[0].children
        if(jjfa.length > 5){
          this.jjarr = jjfa.slice(0, 5);
        }else{
          this.jjarr = jjfa
        }
        // console.log("解决方案",this.jjarr)
      })
    },
    jiejue(item){
      // console.log(item)
      let arr = []
      arr.push(item.parentId)
      sessionStorage.setItem("lanmuarr", JSON.stringify(arr));
      this.$router.push({
        path: item.path,
        query: { id: item.id,typeid: item.parentId},
      });
    },
  }
};
</script>

<style>
.urlaa{
  text-decoration:none !important;
  color: #6d6d6d;
  font-size: 14px;
}


.bottom_bg {
  width: 100%;
  background: #f6f6f6;
  margin-top: 58px;
}
.bottom {
  width: 1200px;
  margin: auto;
}
.bottom_type {
  width: 100%;
  /* padding: 42px 0px 36px 0px; */
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; */
}
.type_left {
  flex: 1;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.type_leftone {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
}

.left_title {
  width: 60px;
  margin-right: 30px;
  font-size: 14px;
  font-family: "Microsoft YaHei-Bold", "Microsoft YaHei";
  font-weight: bold;
  color: #333333;
  line-height: 16px;
}
.left_detail {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.left_detail > span {
  cursor: pointer;
  font-size: 14px;
  font-family: "Microsoft YaHei-Regular", "Microsoft YaHei";
  font-weight: 400;
  color: #6d6d6d;
  line-height: 16px;
  margin: 0px 24px 5px 0px;
}
.type_right {
  width: 600px;
  min-height: 60px;
  box-sizing: border-box;
  padding: 0px 50px;
  border-left: 1px solid #e4e4e4;
  font-size: 14px;
  font-family: "Microsoft YaHei-Regular", "Microsoft YaHei";
  font-weight: 400;
  color: #6d6d6d;
  line-height: 16px;
  /* display: flex;
  flex-direction: column;
  align-items: baseline; */
  /* justify-content: space-between; */
}
.type_rightitem{
  margin-top: 25px;
  text-align: left;
}
.type_rightitemo{
  text-align: left;
  /* margin-top: -10px; */
}
.bottom_info {
  width: 100%;
  padding: 20px 0px 24px 0px;
  font-size: 14px;
  font-family: "Microsoft YaHei-Regular", "Microsoft YaHei";
  font-weight: 400;
  color: #6d6d6d;
  line-height: 16px;
  border-top: 1px solid #e4e4e4;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>
<!-- 以下是友情链接 联系我们样式 -->
<style>
.bom-H{
  display: flex;
  /* padding: 20px 0px; */
  padding-bottom: 10px;
}
.bom-Hxian{
  padding-top: 40px;
  /* border-bottom: 1px solid  #E4E4E4; */
}
.bom-H-L{
  width: 80px !important;
  margin-right: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  height: 100%;

}

.bom-H-R{
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  

}

.yqitem{
  /* padding-right: 10px; */
  /* border-right:  1px  solid  #6d6d6d; */
  /* margin-right: 20px; */
  border-left:  1px  solid  #6d6d6d;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
}
/* .yqitem:last-child{

  border-right:  none;

  margin-right: 0px;

} */

.lxitem{
  font-size: 14px;
  color: #6d6d6d;
  margin-right: 30px;
  margin-bottom: 10px;
}

.lxitem:last-child{

  margin-right: 0px;

}
</style>