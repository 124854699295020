<!--
 * @Description: 
 * @Author: xiaojianping
 * @Date: 2023-03-22 15:18:52
 * @LastEditTime: 2023-05-06 16:56:15
 * @LastEditors: xiaojianping
-->

<template>
  <div id="app" v-cloak>
    <!-- <div style="width: 100%;height: 100%; background-color: #000; position: fixed; z-index: 999999;"></div> -->
    <TopNav v-if="isshow" style="min-width: 1200px"></TopNav>
    <router-view style="padding-top: 80px;min-width: 1200px;" v-if="isshow"></router-view>
    <BottomNav v-if="isshow" style="min-width: 1200px;"></BottomNav>
  </div>
</template>

<script>
import {get} from '@/api/api'
// import HelloWorld from './components/HelloWorld.vue'
import TopNav from "@/components/TopNav/index.vue";
import BottomNav from "@/components/BottomNav/index.vue";
export default {
  name: "App",
  components: {
    TopNav,
    BottomNav,
  },
  watch: {
    // $route(to, from) {
    //         // to 和 from 都是 路由信息对象
    //         if(to.path=='/login'){
    //             this.islogin = false
    //         }
    //     }
  },

  data() {
    return {
      isshow:false,
      islogin:true,
      isloding:true
    };
  },
  mounted() {
    this.getwebid()
  },
  methods:{
    getwebid(){
      let hrefl = location.href.split("//")[1].split("/")[0]
      // console.log("hrefl",hrefl)
      sessionStorage.setItem("hrefl", hrefl);

      get("/web/site/"  + hrefl).then(res=>{
        if(res.code == 200){
          //进入网页就把id存起来
          let url =   res.data.managementSystem.replace(/gydsj-pc/g,"gydsj-api");
          sessionStorage.setItem("siid", res.data.siteMId);
          sessionStorage.setItem("Appurl", url);
          // console.log(sessionStorage.getItem("Appurl"))
          this.isshow = true
        }else{
          this.$message({
          showClose: true,
          message: '没有找到对应的站点',
          type: 'warning'
        });
      }
      })
    },
  }
};
</script>

<style>
* {
  margin: 0;
}
[v-cloak] {display:none !important}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
}

/* v-clock 
[v-cloak] {display:none !important} */
</style>
