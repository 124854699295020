// import Router from "vue-router"
import Router from "vue-router"
import vue from "vue"

vue.use(Router)


//配置路由
export const constantRoutes = [{
		path: '/',
		name: 'Main',
		component: () => import('@/views/main.vue'),
		meta: {
			title: '山东国赢大数据产业有限公司-首页',
			keepAlive: true, // 需要被缓存
		},
		children: []
	},
	//公司产品
	{
		path: '/product',
		component: () => import('@/views/product/index.vue'),
		name: 'product',
		meta: {
			title: '山东国赢大数据产业有限公司-公司产品',
		},
	},
	//解决方案
	{
		path: '/Solution',
		component: () => import('@/views/solution/index.vue'),
		name: 'Solution',
		meta: {
			title: '山东国赢大数据产业有限公司-解决方案',
		},
	},
	//合作案例
	{
		path: '/cooperate',
		component: () => import('@/views/cooperate/index.vue'),
		name: 'cooperate',
		meta: {
			title: '山东国赢大数据产业有限公司-合作案例',
		},
		children: [{
			path: '/',
			//也不使用懒加载，先在文件头部提前引入
			component: () => import('@/views/cooperate/main/index.vue'),
			meta: {
				title: '山东国赢大数据产业有限公司-合作案例',
			},
		}]
	},
	{
		path: '/cooperate/detail',
		//也不使用懒加载，先在文件头部提前引入
		component: () => import('@/views/cooperate/detail/index.vue'),
		name: 'detail',
		meta: {
			title: '山东国赢大数据产业有限公司-合作案例',
		},
	},
	//关于我们
	{
		path: '/aboutus',
		component: () => import('@/views/aboutus/index.vue'),
		name: 'aboutus',
		meta: {
			title: '山东国赢大数据产业有限公司-关于我们',
		},
		children: [{
				path: '/',
				//也不使用懒加载，先在文件头部提前引入
				component: () => import('@/views/aboutus/components/index.vue'),
				meta: {
					title: '山东国赢大数据产业有限公司-关于我们',
				},
			},
			{
				path: 'infolist',
				component: () => import('@/views/aboutus/components/infolist/index.vue'),
				meta: {
					title: '山东国赢大数据产业有限公司-关于我们',
				},
			},
			{
				path: 'detail',
				component: () => import('@/views/aboutus/components/infodetail/index.vue'),
				meta: {
					title: '山东国赢大数据产业有限公司-关于我们',
				},
			},
		]
	},

	{
		path: '/login',
		name: 'Login',
		component: () => import('@/views/Login')
	}
	,
	// {
	// 	path: '/isroll',
	// 	name: 'Isroll',
	// 	component: () => import('@/views/isroll')
	// }
]
// 防止连续点击多次路由报错
let routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
	return routerPush.call(this, location).catch(err => err)
}
export default new Router({
	base: '/',
	mode: 'history', // 去掉url中的#
	scrollBehavior: () => ({
		y: 0
	}),
	routes: constantRoutes
})