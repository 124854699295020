<template>
    <div class="Amain">
        <div v-for=" (item, index) in menuData" :key="index" class="Ainner">
            <el-submenu :index=item.id.toString() v-if="item.children && item.children.length > 0">
                <template slot="title">
                    <img class="img" :src="url + item.columnPictrue" v-if="item.columnPictrue != null" />
                    <img class="img imgno" src="@/assets/image/tab-icon1.png" v-if="item.columnPictrue == null && item.parentId != 0"  />
                    {{ item.label }}
                </template>
                <Menu :menuData="item.children"></Menu>
            </el-submenu>
            <el-menu-item :index=item.id.toString() :route="{ path: item.path, query: { id: item.id } }" v-else>
                <img class="img" :src="url + item.columnPictrue" v-if="item.columnPictrue != null" />
                <img class="img imgno" src="@/assets/image/tab-icon1.png" v-if="item.columnPictrue == null && item.parentId != 0" />
                {{ item.label }}
            </el-menu-item>
        </div>
    </div>
</template>

<script>
export default {
  name: "Menu",
  props: {
    menuData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {},
  data() {
    return {
      url:''
    };
  },
  mounted() {
    this.url = sessionStorage.getItem("A_URL");
  }
};
</script>

<style scoped>
.menuA .Amain{
    overflow: hidden;
}
.menuA .Amain .Ainner{
    float: left;
}
.Ainner{
    font-size: 16px !important;
}
.Ainner > li {
  height: 80px !important;
  line-height: 80px !important;
  background: none !important;
}
.Ainner > li > :first-child {
  height: 80px;
  line-height: 80px !important;
  font-size: 16px !important;
  background: none !important;
}
.el-menu-item {
  font-size: 16px !important;
}
.img {
  margin-right: 10px;
  width: 30px !important;
  height: 30px !important;
} 
.imgno{
  opacity:0;
}
</style>
