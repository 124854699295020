import Cookie from "js-cookie";
	
export default {
	state: {
		token: "",
	},
	mutations: {
		// 设置cookie
		setToken(state, val) {
			(state.token = val), Cookie.set("token", val); //cookie的名称，传入的值
		},
		// 清除cookie
		clearToken(state) {
			(state.token = ""), Cookie.remove("token");
		},
		// 获取cookie
		getToken(state) {
			// 如果当前的缓存中有token，直接获取。如果没有，要从state中获取
			state.token = Cookie.get("token") || state.token;
		},
	},
};
