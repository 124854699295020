/*
 * @Description: 
 * @Author: xiaojianping
 * @Date: 2023-05-16 18:23:41
 * @LastEditTime: 2023-05-16 18:23:52
 * @LastEditors: xiaojianping
 */
const Base64 = {
    //加密
     encode(str) {
         return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
             function toSolidBytes(match, p1) {
                 return String.fromCharCode('0x' + p1);
             }));
     },
   //解密
     decode(str) {
         // Going backwards: from bytestream, to percent-encoding, to original string.
         return decodeURIComponent(atob(str).split('').map(function (c) {
             return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
         }).join(''));
     }
 }
 export default Base64
 