/*
 * @Description:
 * @Author: xiaojianping
 * @Date: 2023-03-22 16:06:12
 * @LastEditTime: 2023-07-06 13:51:18
 * @LastEditors: xiaojianping
 */
import axios from "axios";
import Qs from "qs";
let qs = Qs;

// const BASE_URL = sessionStorage.getItem("Appurl") ?  sessionStorage.getItem("Appurl")  : "http://192.168.0.9:48889/gydsj-api"; //我们线上的

// const BASE_URL =  "http://192.168.0.9:48889/gydsj-api";


// const BASE_URL = "http://192.168.0.118:48888"; //崔子硕

// const BASE_URL = "http://192.168.0.140:48888"; //崔子硕


const BASE_URL =  sessionStorage.getItem("Appurl") ?  sessionStorage.getItem("Appurl")  :window.location.href.split("//")[0]  + "//"+ window.location.href.split("//")[1].split("/")[0] + "/gydsj-api"; //龙哥让改的

sessionStorage.setItem("A_URL", BASE_URL);



// 创建axios实例对象
let instance = axios.create({
  baseURL: BASE_URL,
  timeout: 5000,
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // console.log(config,'请求配置项');
    //   if(config.url !== '登录接口'){
    //     config.headers.Authorization = 'token值'
    //   }
    return config;
  },
  (error) => {
    alert("请求失败");
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    let res = response.data;
    return res;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 封装get方法并导出
export function get(url, params) {
  return instance.get(url, {
    params,
  });
}
// 封装postJSON方法 (发送json格式数据)
export function postJSON(url, data) {
  return instance.post(url, data);
}
// 封装post方法 (发送表单格式数据)
export function post(url, data) {
  return instance.post(url, qs.stringify(data));
}

// 导出axios实例
export default instance;
