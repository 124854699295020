import Vuex from "vuex";
import Vue from "vue"
import tab from "./tab"
import user from "./user"
Vue.use(Vuex)

const store = new Vuex.Store({
	modules:{
		tab,
		user
	},
	getters:{}
})
export default store